import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {VkmService} from '../../services/vkm.service';
import {VkmAnswer} from '../../classes/vkm-answer';
import {User} from '../../classes/user.class';
import {VkmChecklistComponent} from '../vkm-checklist/vkm-checklist.component';

@Component({
    selector: 'app-vkm-checklist-overview',
    templateUrl: './vkm-checklist-overview.component.html',
    styleUrls: ['./vkm-checklist-overview.component.scss']
})
export class VkmChecklistOverviewComponent extends CodaltComponent implements OnInit {

    results: {
        user: User,
        controlepunten: VkmAnswer[],
        werkoverdracht: VkmAnswer[]
    }[]

    constructor(
        public dialogRef: MatDialogRef<VkmChecklistOverviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            planning_has_id: number,
            usersMap: Map<number, User>
        },
        private vkmService: VkmService,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.vkmService.getAllAnswers(this.data.planning_has_id).subscribe(answers => {
            this.results = [];
            answers.data.forEach((answer) => {
                this.results.push({
                    user: this.data.usersMap.get(answer.user_id),
                    controlepunten: answer.answers.filter(a => a.type === 'controlepunten'),
                    werkoverdracht: answer.answers.filter(a => a.type === 'werkoverdracht')
                });
            });
        }));
    }

    showAnswers(type: 'controlepunten' | 'werkoverdracht', answers) {
        const ref = this.dialog.open(VkmChecklistComponent, {
            data: {
                type,
                planning_has_id: this.data.planning_has_id,
                answers: answers
            },
            maxWidth: '100vw'
        });
        const subs = ref.afterClosed().subscribe(() => {

            subs.unsubscribe()
        });
    }

}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EntitiesService} from '../../services/entities/entities.service';
import {Entity} from '../../classes/entity.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EntityUnavailable} from '../../classes/entityunavailable.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {DateAdapter} from '@angular/material/core';
import {DateFullMonthAdapter} from '../../date-adapters/date-full-month-adapter';
import {daterangeAvailableValidator, DateRangeValidatorType} from '../../validators/daterange-available.validator';
import {PlanningService} from '../../services/planning/planning.service';
import {Utils} from '../../utils.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EntityType, EntityTypeCode} from '../../services/entities/entity-type.class';
import {UserService, UserType} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {Employees} from '../../afas-classes/employees.class';
import {AfasService} from '../../services/afas.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-entity-detail',
    templateUrl: './entity-detail.component.html',
    styleUrls: ['./entity-detail.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: DateFullMonthAdapter}
    ]
})
export class EntityDetailComponent implements OnInit, OnDestroy {

    unavailablePlanningForm: Map<EntityUnavailable, FormGroup>;
    form: FormGroup;
    saving = false;
    title = '';
    submitted = false;
    truckDrivers: User[];
    entityTeams: Entity[];
    entityTrucks: Entity[];
    entityLowloaders: Entity[];
    entitytypes: EntityType[];
    employees: User[];
    executors: User[];
    asphaltExecutors: User[];
    viewState = {
        driver: false,
        preferredTeam: false,
        truck: false,
        lowloader: false,
        employees: false,
        asphaltPerformer: false,
        performer: false,
        vkm: false
    };
    afasMaterieel: Employees[];

    EntityTypeCode = EntityTypeCode;

    private subscriptions = new Subscription();

    constructor(@Inject(MAT_DIALOG_DATA) public entity: Entity,
                private confirmDialog: ConfirmDialogService,
                public dialogRef: MatDialogRef<EntityDetailComponent>,
                private userService: UserService,
                private planningService: PlanningService,
                private entitiesService: EntitiesService,
                private afasService: AfasService) {
    }

    ngOnInit() {
        this.unavailablePlanningForm = new Map<EntityUnavailable, FormGroup>([]);
        this.userService.getList(false, false).subscribe(truckDrivers => {
            this.truckDrivers = truckDrivers;
        });

        this.subscriptions.add(this.entitiesService.getTypes().subscribe(types => {
            this.entitytypes = types.data;
        }));

        this.entitiesService.getByType(EntityTypeCode.AsfaltTeam).subscribe(entities => {
            this.entityTeams = entities.filter(e => e.enddate === null || new Date(e.enddate).getTime() >= (new Date().getTime()));
        });
        this.entitiesService.getByType(EntityTypeCode.Truck).subscribe(entities => {
            this.entityTrucks = entities.filter(e => e.enddate === null || new Date(e.enddate).getTime() >= (new Date().getTime()));
        });
        this.entitiesService.getByType(EntityTypeCode.LowLoader).subscribe(entities => {
            this.entityLowloaders = entities.filter(e => e.enddate === null || new Date(e.enddate).getTime() >= (new Date().getTime()));
        });

        if (this.entity.id) {
            this.entitiesService.get(this.entity.id).then(entity => {
                this.createForm(entity);
                this.entity = entity;
                entity.entity_unavailable.forEach(unavailable => {
                    this.unavailablePlanningForm.set(unavailable, this.createUnavailableForm(unavailable));
                });

            });
        } else {
            this.createForm(this.entity);
            if (this.entity.id && this.entity.entity_unavailable) {
                this.entity.entity_unavailable.forEach(unavailable => {
                    this.unavailablePlanningForm.set(unavailable, this.createUnavailableForm(unavailable));
                });
            }
        }
        this.subscriptions.add(this.entitiesService.getTypesMap().subscribe(data => {
            this.title = data.get(this.entity.entitytypes[0]?.id)?.name;
        }));

        this.subscriptions.add(this.afasService.getMaterieel().subscribe(materieel => {
            this.afasMaterieel = materieel.data;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    createForm(entity: Entity) {
        this.viewState.preferredTeam = Utils.entitytypeProperty(entity.entitytypes, 'has_preferred_team');
        this.viewState.driver = Utils.entitytypeProperty(entity.entitytypes, 'has_driver');
        this.viewState.truck = Utils.entitytypeProperty(entity.entitytypes, 'has_truck');
        this.viewState.lowloader = Utils.entitytypeProperty(entity.entitytypes, 'has_lowloader');
        this.viewState.employees = Utils.entitytypeProperty(entity.entitytypes, 'has_employees');
        this.viewState.asphaltPerformer = Utils.entitytypeProperty(entity.entitytypes, 'has_default_asphalt_performer');
        this.viewState.performer = Utils.entitytypeProperty(entity.entitytypes, 'has_default_performer');
        this.viewState.vkm = !!entity.entitytypes.map(et => et.id).find(e => [EntityTypeCode.VkmMateriaal, EntityTypeCode.VkmMateriaal].includes(e));

        this.form = new FormGroup({
            name: new FormControl(entity.name, [Validators.maxLength(60), Validators.required]),
            entitytypes: new FormControl(entity.entitytypes?.map(e => e.id)),
            default_employees: new FormControl(entity.default_employees),
            default_performer_id: new FormControl(entity.default_performer_id),
            default_asphalt_performer_id: new FormControl(entity.default_asphalt_performer_id),
            driver_user_id: new FormControl(entity.driver_user_id),
            team_entity_id: new FormControl(entity.team_entity_id),
            afas_employee_id: new FormControl(entity.afas_employee_id),
            truck_entity_id: new FormControl(entity.truck_entity_id),
            lowloader_entity_id: new FormControl(entity.lowloader_entity_id),
            begindate: new FormControl(
                entity.begindate ? entity.begindate : new Date(),
                [Validators.required],
                [daterangeAvailableValidator(this.planningService, entity.id, DateRangeValidatorType.beginAsEnd, true)]),
            enddate: new FormControl(
                entity.enddate,
                [],
                [daterangeAvailableValidator(this.planningService, entity.id, DateRangeValidatorType.endAsBegin, true)]),
            exclude_driving_hours: new FormControl(entity.exclude_driving_hours),
            default_transport_none: new FormControl(entity.default_transport_none),
            default_transport_home: new FormControl(entity.default_transport_home),
            price: new FormControl(entity.price),
            price_unit: new FormControl(entity.price_unit),
            unit: new FormControl(entity.unit),
            settle_group: new FormControl(entity.settle_group),
            price_plotten: new FormControl(entity.price_plotten),
            coupling_clamps: new FormControl(entity.coupling_clamps),
            ground_pot: new FormControl(entity.ground_pot)
        });

        if (this.viewState.employees) {
            this.subscriptions.add(this.userService.getList(false, false).subscribe(users => {
                this.employees = users;
            }));
        }
        if (this.viewState.performer) {
            this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
        }
        if (this.viewState.asphaltPerformer) {
            this.subscriptions.add(this.userService.getByType(UserType.ASPHALTEXECUTOR).subscribe(users => this.asphaltExecutors = users));
        }
    }

    createUnavailableForm(unavailable) {
        return new FormGroup({
            description: new FormControl(unavailable.description, [Validators.maxLength(300), Validators.required]),
            begindate: new FormControl(unavailable.begindate, [Validators.required]),
            enddate: new FormControl(unavailable.enddate, [Validators.required])
        }, [], [daterangeAvailableValidator(this.planningService, this.entity.id, DateRangeValidatorType.normal, true)]);
    }

    addUnavailable() {
        if (!this.entity.entity_unavailable) {
            this.entity.entity_unavailable = [];
        }
        const unavailable = new EntityUnavailable();
        this.entity.entity_unavailable.push(unavailable);
        this.unavailablePlanningForm.set(unavailable, this.createUnavailableForm(unavailable));
    }

    close() {
        this.dialogRef.close();
    }

    allFormsValid(): boolean {
        let allValid = true;
        this.unavailablePlanningForm.forEach((formGroup: FormGroup, unavailable: EntityUnavailable) => {
            if (!formGroup.valid) {
                for (const name of Object.keys(formGroup.controls)) {
                    formGroup.controls[name].markAsTouched();
                }
            }
            if (allValid) {
                allValid = formGroup.valid;
            }
        });

        return allValid;
    }

    deleteUnavailable(event, unavailable: EntityUnavailable) {
        event.preventDefault();
        event.stopPropagation();
        const index = this.entity.entity_unavailable.indexOf(unavailable);
        this.entity.entity_unavailable.splice(index, 1);
        this.unavailablePlanningForm.delete(unavailable);

    }

    save() {
        this.submitted = true;
        this.saving = true;
        if (this.form.valid && this.allFormsValid()) {
            Object.assign(this.entity, this.form.value);
            this.unavailablePlanningForm.forEach((formGroup: FormGroup, unavailable: EntityUnavailable) => {
                Object.assign(unavailable, formGroup.value);
                this.setTime(unavailable);
            });
            this.setTime(this.entity);
            this.entitiesService.save(this.entity).then(decoration => {
                this.saving = false;
                this.close();
            }, error => {
                this.saving = false;
                this.confirmDialog.confirm(
                    'Fout tijdens het opslaan',
                    'Er is iets fout gegaan tijdens het opslaan',
                    'Oké', null);
            });
        } else {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
            this.saving = false;
        }

    }

    setTime(toObject) {
        if (toObject.begindate) {
            toObject.begindate = new Date(toObject.begindate);
            Utils.setTime(toObject.begindate, 0, 0);
        }
        if (toObject.enddate) {
            toObject.enddate = new Date(toObject.enddate);
            Utils.setTime(toObject.enddate, 23, 59);
        }
    }
}

interface EntityDetailParams {
    id: number;
    type: number;
}

import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VkmService} from '../../services/vkm.service';
import {CodaltComponent} from '../../codalt.component';
import {VkmQuestion} from '../../classes/vkmQuestion';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Utils} from '../../utils.class';
import {VkmAnswer} from '../../classes/vkm-answer';

@Component({
    selector: 'app-vkm-checklist',
    templateUrl: './vkm-checklist.component.html',
    styleUrls: ['./vkm-checklist.component.scss']
})
export class VkmChecklistComponent extends CodaltComponent implements OnInit {
    checklistForm: FormGroup;
    questions: VkmQuestion[] = [];

    constructor(
        public dialogRef: MatDialogRef<VkmChecklistComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            type: 'controlepunten' | 'werkoverdracht',
            planning_has_id: number,
            answers: VkmAnswer[];
        },
        private vkmService: VkmService,
        private snackBar: MatSnackBar,
    ) {
        super();
        this.checklistForm = new FormGroup({
            type: new FormControl(this.data.type, Validators.required),
            comment: new FormControl(''),
            planning_has_id: new FormControl(this.data.planning_has_id, Validators.required),
            answers: new FormArray([])
        });
    }

    ngOnInit(): void {
        this.loadQuestions();
    }

    loadQuestions(): void {
        this.subscriptions.add(this.vkmService.getQuestions().subscribe(questions => {
            this.questions = questions.data[this.data.type];
            this.initAnswers();
        }));
    }

    initAnswers(): void {
        const answersArray = this.checklistForm.get('answers') as FormArray;
        this.questions.forEach((question) => {
            let currentAnswer = this.data.answers?.find((answer) => answer.vkm_question_id === question.id)?.answer ?? null;
            if (this.data.type === 'werkoverdracht') {
                currentAnswer = (currentAnswer === 'JA') as any;
            }
            const fg = new FormGroup({
                vkm_question_id: new FormControl(question.id, Validators.required),
                answer: new FormControl(currentAnswer)
            });
            if (!question.icon) {
                fg.get('answer').addValidators([Validators.required]);
            }
            answersArray.push(fg);
            if (!this.checklistForm.value.comment) {
                this.checklistForm.get('comment').setValue(this.data.answers?.find((answer) => answer.vkm_question_id === question.id)?.comment);
            }
        });
    }

    onSubmit(): void {
        Utils.triggerValidationP(this.checklistForm).then(() => {
            this.subscriptions.add(this.vkmService.saveAnswers(this.checklistForm.value).subscribe(() => {
                this.dialogRef.close();
            }));
        }, () => {
            this.snackBar.open('Niet alle vragen zijn correct ingevuld', null, {duration: 3000});
        });

    }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserGroups, UserService, UserType} from '../../services/user/user.service';
import {DefaultStand, TraveltimeWeekend, TraveltimeWorkday, User} from '../../classes/user.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Group} from '../../classes/group.class';
import {AfasService} from '../../services/afas.service';
import {Subscription} from 'rxjs';
import {Employees} from '../../afas-classes/employees.class';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {

    TraveltimeWorkday = TraveltimeWorkday;
    TraveltimeWeekend = TraveltimeWeekend;
    DefaultStand = DefaultStand;

    showPin = false;
    newPin: string;

    saving = false;
    title = '';
    UserGroups = UserGroups;
    form: FormGroup;
    userGroupKeys = [...UserGroups.keys()];
    groups: Group[];
    employees: Employees[];
    employee: Employees;
    users: User[];
    private subscriptions = new Subscription();

    travelTimeNameMap = new Map<any, string>([
        [TraveltimeWorkday.Geen, 'Geen'],
        [TraveltimeWorkday.Staffel, 'Staffel'],
        [TraveltimeWorkday.Werkelijk, 'Werkelijk']
    ]);

    constructor(@Inject(MAT_DIALOG_DATA) public user: User,
                private userService: UserService,
                public dialogRef: MatDialogRef<UserEditComponent>,
                public afasService: AfasService) {
    }

    ngOnInit() {
        this.showPin = !this.user.groups?.find(g => g.password);
        this.createForm(this.user);
        this.userService.getGroups().then(groups => {
            this.groups = groups;
        });

        this.subscriptions.add(this.userService.getList().subscribe(users => this.users = users));

        this.subscriptions.add(this.afasService.getEmployees().subscribe(emp => {
            this.employees = emp.data;
            this.employee = this.employees.find(e => e.employeeId === this.user?.afas_employee_id);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getNewPin() {
        this.userService.newUserPin(this.user.id).subscribe(pin => {
            this.newPin = pin.data;
            setTimeout(() => {
                this.newPin = null;
            }, 300000);
        })
    }

    save() {
        Object.assign(this.user, this.form.value);
        this.saving = true;
        this.userService.saveUser(this.user).then(user => {
            this.saving = false;
            this.close();
        }, error => {
            this.saving = false;
        });
    }

    close() {
        this.dialogRef.close();
    }

    private createForm(user: User) {
        this.title = user.id ? 'aanpassen' : 'toevoegen';
        this.form = new FormGroup({
            name: new FormControl(user.name, [Validators.required, Validators.minLength(3)]),
            email: new FormControl(user.email, [Validators.required, Validators.email]),
            groups: new FormControl(user.groups ? user.groups.map(g => g.id) : [], [Validators.required]),
            default_stand: new FormControl(user.default_stand),
            traveltime_workday: new FormControl(user.traveltime_workday),
            traveltime_weekend: new FormControl(user.traveltime_weekend),
            updated_at: new FormControl(user.updated_at),
            overtime: new FormControl(user.overtime),
            hours_checked_by: new FormControl(user.hours_checked_by)
        });
    }

}

interface UserEditParams {
    userId: number;
}

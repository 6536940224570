<form [formGroup]="checklistForm" (ngSubmit)="onSubmit()" class="checklist-form">
    <div>
        <h1>{{data.type}}</h1>
    </div>
    <div class="all-questions">
        <div formArrayName="answers">
            <div *ngFor="let question of questions; let i = index" [formGroupName]="i" class="question">
                <div *ngIf="!question.icon">
                    <div>{{question.order}}. {{question.question}}</div>
                    <div class="ml-3 mt-2">
                        <mat-radio-group formControlName="answer">
                            <mat-radio-button value="JA" class="mr-2">Ja</mat-radio-button>
                            <mat-radio-button value="NEE" class="mr-2">nee</mat-radio-button>
                            <mat-radio-button value="NVT">N.v.t.</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div *ngIf="question.icon" class="icon-check">
                    <img (click)="checklistForm.get('answers').get(i+ '').get('answer').setValue(!item.checked)" src="/assets/images/vkm-icons/{{question.icon}}"/>
                    <div class="check">
                        <mat-checkbox #item formControlName="answer" value="JA">
                            {{question.question}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <mat-form-field appearance="fill" class="form-field w-100">
            <mat-label>Opmerking</mat-label>
            <textarea matInput formControlName="comment"
                      placeholder="Opmerking als bovenstaande niet akkoord is (stagnaties, aanwijzingen bevoegd gezag, afwijkingen, incidenten):"></textarea>
        </mat-form-field>
    </div>

    <div class="actions">
        <div class="d-flex justify-content-between">
            <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Sluiten</button>
            <button mat-raised-button class="planning-button confirm" color="primary" type="submit">Opslaan</button>
        </div>
    </div>
</form>

<div>
    <h2>Resultaten controlepunten en werkoverdracht</h2>
</div>
<table class="table">
    <tr>
        <th>
            Gebruiker
        </th>
        <th>
            Anwoorden
        </th>
    </tr>
    <tr *ngFor="let result of results">
        <td>
            {{result.user.name}}
        </td>
        <td>
            <div class="d-flex">
                <a class="mr-1 curpoint" *ngIf="result.controlepunten?.length"
                     (click)="showAnswers('controlepunten',result.controlepunten)">
                    Controlepunten
                </a>
                <a *ngIf="result.werkoverdracht?.length"
                     class="curpoint"
                     (click)="showAnswers('werkoverdracht',result.werkoverdracht)">
                    Werkoverdracht
                </a>
            </div>
        </td>
    </tr>
</table>
<div class="actions">
    <div class="d-flex justify-content-between pb-2">
        <button mat-raised-button class="planning-button cancel w-100" mat-dialog-close="">Sluiten</button>
    </div>
</div>

import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Asphaltmill} from '../../classes/asphaltmill.class';
import {Asphalt} from '../../classes/asphalt.class';
import {Entity} from '../../classes/entity.class';
import {AsphaltmillAsphalt} from '../../classes/asphaltmillasphalt.class';
import {Observable} from 'rxjs';
import {formatDate} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AsphaltService {

    private urlPrefix = 'asphalt';
    private asphaltMills: Asphaltmill[] = [];
    private asphaltList: Asphalt[] = [];

    static applicationListAdhesive = [
        {name: 'Toplaag', key: 'adhesive_surf'},
        {name: 'Tussenlaag', key: 'adhesive_bind'},
        {name: 'Onderlaag', key: 'adhesive_base'}
    ];

    static applicationListAsphalt = [
        {name: 'Toplaag', key: 'surf'},
        {name: 'Tussenlaag', key: 'bind'},
        {name: 'Onderlaag', key: 'base'}
    ];

    constructor(private apiService: ApiService) {
    }

    public getAsphaltMills(cached = false): Promise<Asphaltmill[]> {
        return this.apiService.getCall(`${this.urlPrefix}/mills`);
    }

    public generateList(mill: Asphaltmill, date: Date) {
        const dateString = formatDate(date, 'yyyy-MM-dd', 'nl');
        return this.apiService.getBlobCall(`${this.urlPrefix}/asphaltlist/${mill.id}/${dateString}`);
    }

    public getAsphaltList(cached = true): Promise<Asphalt[]> {
        return new Promise((resolve, reject) => {
            if (this.asphaltList && this.asphaltList.length > 0 && cached) {
                resolve(this.asphaltList);
            } else {
                this.apiService.getCall(`${this.urlPrefix}`).then((asphaltList: Asphalt[]) => {
                    this.asphaltList = asphaltList;
                    resolve(asphaltList);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public exportAsphaltListToExcel(): Observable<any> {
        return this.apiService.getBlobCall(`${this.urlPrefix}/export`);
    }

    public importCorrection(data): Promise<any> {
        return this.apiService.postCall(`${this.urlPrefix}/import/correction`, data);
    }

    public deleteAsphalt(asphalt: Asphalt): Promise<Entity> {
        return this.apiService.deleteCall(`${this.urlPrefix}/${asphalt.id}`);
    }

    public deleteAsphaltMill(asphaltmill: Asphaltmill): Promise<Entity> {
        return this.apiService.deleteCall(`${this.urlPrefix}/mill/${asphaltmill.id}`);
    }

    public deleteAsfaltMillAsphalt(asphaltmillAsphalt: AsphaltmillAsphalt): Promise<Entity> {
        return this.apiService.deleteCall(`${this.urlPrefix}/mill/asphalt/${asphaltmillAsphalt.id}`);
    }
}
